import React, { useState, useContext, useEffect } from "react";
import { Elements, useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import "./purchase.css";
import Grid from "@material-ui/core/Grid";
import { tiers } from "../components/PricingData.js";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";
import { CardHeader, TextField, Typography } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import app from "../services/firebase";
import { AuthContext } from "../services/Auth";
import useLocalStorage from "../hooks/useLocalStorage";

import BeatLoader from "react-spinners/BeatLoader";

const stripeKey = "pk_live_i8YSFkV5j6lDu1slejYmWpe4";//Should be publishable
const stripePromise = loadStripe(stripeKey);

const CheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const { currentUser } = useContext(AuthContext);
  //const [purchaseData] = useRecoilState(purchaseState);
  const [purchaseData] = useLocalStorage("PurchaseState");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  //const [priceKey] = useRecoilValue(priceKeyState);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setErrorMessage(""); //Clear an error messages
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    const cardElement = elements.getElement(CardElement);
    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });
    console.log(purchaseData);

    if (error) {
      console.log("[error]", error);
    } else {
      console.log("[PaymentMethod]", paymentMethod);
      createSubscription(
        currentUser.uid,
        currentUser.stripe_id,
        paymentMethod.id,
        `${props.products} - ${props.tier}`,
        purchaseData.total * 100,
        purchaseData.billed === "Annually" ? "year" : "month",
        purchaseData.couponCode
      );
    }
  };
  const createSubscription = (userId, customerId, paymentMethodId, productName, unitAmount, unitInterval,couponCode) => {
    var createStripeSubscription = app.functions().httpsCallable("createStripeSub");
    createStripeSubscription({ userId,customerId, paymentMethodId, productName, unitAmount, unitInterval,couponCode })
      .then((result) => {
        console.log("result", result);
        setErrorMessage("");
        props.history.push("/subscription");
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage(error.message);
      });
  };
  return (
    <>
      <form onSubmit={handleSubmit} style={{ display: "flex", flexDirection: "column" }}>
        <CardElement />
        {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : null}
        <Button variant="contained" color="primary" type="submit" disabled={!stripe || loading} style={{ marginBottom: "20px" }}>
          {loading ? "Busy" : "Pay"}
        </Button>
      </form>
    </>)
};

const getLicenses = (state) => {
  let licenses = state.products.reduce((total, item) => {
    return total + (item.checked ? (total === "" ? "" : ",") + item.name : "");
  }, "");
  return licenses;
};

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  cardHeader: {
    backgroundColor: theme.palette.type === "light" ? theme.palette.grey[200] : theme.palette.grey[700],
  },
  card: {
    margin: "20px",
  },
  centerElement: {
    display: "flex",
    flexDirection: "column",
  },
  couponCode: {
    marginTop:-20,
  }
}));

const Purchase = (props) => {
  const classes = useStyles();
  const [purchaseData,setPurchaseData] = useLocalStorage("PurchaseState");
  const [couponCode,setCouponCode] = useState("");
  const { currentUser } = useContext(AuthContext);
  const [coupons,setCoupons] = useState({});

  useEffect(()=>{
    var db = app.firestore();
    db.collection("stripe_coupons").get().then( snapshot => {
      let newCoupons = {};
      snapshot.forEach(doc => newCoupons[doc.id] = doc.data());
      setCoupons(newCoupons);
    });
  },[]);


  useEffect(()=>{
    let coupon = coupons[couponCode];
    if(coupon && purchaseData.priceIndex > 0){
      let newPurchaseData = {...purchaseData};
      newPurchaseData.discount = coupon.amount;
      newPurchaseData.total =  newPurchaseData.price - (newPurchaseData.price*newPurchaseData.discount);
      newPurchaseData.couponCode = couponCode;
      setPurchaseData(newPurchaseData);
    }else{
      let newPurchaseData = {...purchaseData};
      newPurchaseData.discount = 0;
      newPurchaseData.total =  newPurchaseData.price;
      newPurchaseData.couponCode = "";
      setPurchaseData(newPurchaseData);
    }
  },[couponCode]);

  if(!purchaseData) return null;
  //console.log("PURCHASE",persistedState);
  //const [purchaseData] = useRecoilState(purchaseState,persistedState);
  const tier = tiers[purchaseData.priceIndex];
  const products = getLicenses(purchaseData);

  return (
    <Container maxWidth="sm" component="main">
      <Card className={classes.card}>
        <Grid container spacing={1} alignItems="center" justify="center" className={classes.grow}>
          <Grid item xs={12}>
            <CardHeader title="Create Subscription" className={classes.cardHeader} />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={2}>
            Plan:
          </Grid>
          <Grid item xs={9}>
            {tier.title}
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={2}>
            Billed:
          </Grid>
          <Grid item xs={9}>
            {purchaseData.billed}
          </Grid>

          <Grid item xs={1}></Grid>
          <Grid item xs={2}>
            Products:
          </Grid>
          <Grid item xs={9}>
            {products}
          </Grid>

          <Grid item xs={1}></Grid>
          <Grid item xs={2}>
            Price:
          </Grid>
          <Grid item xs={3}>
            {"$" + purchaseData.total + (purchaseData.billed === "Annually" ? " / yr" : " / mth")}
          </Grid >
          <Grid item xs={6}><TextField id="couponCode" label="Coupon Code" value={couponCode} onChange={(e) => setCouponCode(e.target.value)} className={classes.couponCode} /></Grid>

          <Grid item xs={2}></Grid>
          <Grid item xs={10}>
          <Typography variant="caption" display="block" gutterBottom>
          (Note: Coupons cannot be applied to 'Essential' plans)
      </Typography>
            
            
            </Grid>

          {currentUser.stripe_id === '' ? (
            <BeatLoader/>
          ) : (
            <Grid item xs={11}>
              <div className={classes.centerElement}>
                <Elements stripe={stripePromise}>
                  <CheckoutForm products={products} tier={tier.title} history={props.history} />
                </Elements>
              </div>
            </Grid>
          )}
        </Grid>
      </Card>
    </Container>
  );
};

export default Purchase;
