import { Container, Typography } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import YouTube from "react-youtube";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "80px",
    justifyContent: "center",
    padding: 40,
    backgroundColor: "#F3FCFD",
  },
  containerRev: {
    flexDirection: "row-reverse",
    backgroundColor: "white",
  },
  detailContainer: {
    display: "flex",
    flexDirection: "row",
  },
  titleSection: {
    paddingLeft: 30,
    maxWidth: 400,
  },
  title: {
    color: "#000469",
    fontWeight: 700,
    paddingTop: 75,
  },
  description: {
    padding: theme.spacing(2, 0),
  },
  circle: {
    paddingTop: 55,
  },
  youtube: {
    padding: theme.spacing(0, 0),
  },
}));

const OnBoardSection = (props) => {
  let { number, title , description,videoId,children,image} = props;
  const classes = useStyles();
  const opts = {
    height: "290",
    width: "540",
    playerVars: {
      autoplay: 0,
    },
  };
  return (
    <Container className={`${classes.container} ${number % 2 === 0 && classes.containerRev}`}>
      <div className={classes.detailContainer}>
        <div className={classes.circle}>
          <svg width="70px" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <style></style>
            <circle cx="50" cy="50" r="47" stroke="#828282" strokeWidth="6" fillOpacity="0" />
            <text
              fontFamily="Nunito Sans"
              fontSize="3em"
              x="50%"
              y="50%"
              textAnchor="middle"
              stroke="#828282"
              strokeWidth="2px"
              dy=".3em"
            >
              {number}
            </text>
          </svg>
        </div>
        <div className={classes.titleSection}>
          <Typography variant="h5" className={classes.title}>
            {title}
          </Typography>
          <Typography className={classes.description}>{description}</Typography>
          {children}
        </div>
      </div>
      {videoId && <YouTube videoId={videoId} opts={opts} className={classes.youtube} />}
      {image && <img src={image}/>}
    </Container>
  );
};

export default OnBoardSection;
