import React, { useEffect, useState } from "react";
import app from "./firebase.js";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    var db = app.firestore();
    let stripe_id = '';
    app.auth().onAuthStateChanged((user) => {
      let newUser = { ...user ,stripe_id : ''};
      if(user){
        var docRef = db.collection("stripe_customers").doc(user.uid);
        docRef
          .get()
          .then(function (doc) {
            if (doc.exists) {
              stripe_id = doc.data().customer_id;
              newUser.stripe_id = stripe_id;
              setCurrentUser(newUser);
            } else {
              setCurrentUser({...newUser});
              let unsubscribe = docRef.onSnapshot((doc)=>{
                if(doc.data()){
                  stripe_id = doc.data().customer_id;
                  newUser.stripe_id = stripe_id;
                  setCurrentUser({...newUser});
                  unsubscribe();//No need to listen any more 
                }
              });
              console.log("No such document!");//That's fine since snapshot will wait for it to be created
            }
          })
          .catch(function (error) {
            console.log("Error getting document:", error);
          });
      }else{
        setCurrentUser(null);
      }
    });
  }, []);

  return <AuthContext.Provider value={{ currentUser }}>{children}</AuthContext.Provider>;
};
