import React, { useContext } from 'react';
import {Route,Redirect,useLocation} from 'react-router-dom';
import {AuthContext} from './Auth';

const PrivateRoute = ({component: RouteComponent, ...rest}) =>{
  const {currentUser} = useContext(AuthContext);
  const location = useLocation();
  const {redirect} = rest;
  return (
    <Route
      {...rest}
      render={routeProps => !!currentUser ? 
      (<RouteComponent {...routeProps}/>) 
      : 
      (<Redirect to={{pathname:redirect,state: { from: location.pathname }}} />)
    }
      >


      </Route>
  )
}

export default PrivateRoute;