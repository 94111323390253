import React from "react";
import SignUp from "./pages/SignUp";
import PricingToggle from "./components/PricingToggle";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { AuthProvider } from "./services/Auth";
import PrivateRoute from "./services/PrivateRoute";
import SignIn from "./pages/SignIn";
import Purchase from "./pages/Purchase";
import NavBar from "./components/NavBar";
import { RecoilRoot } from "recoil";
import Subscription from "./pages/Subscription";
import Portal from "./pages/Portal";
import Home from "./pages/Home";
import Footer from "./components/Footer";
import PricingSchools from "./components/PricingSchools";
import OnBoard from "./pages/OnBoard";
import { MuiThemeProvider, unstable_createMuiStrictModeTheme as createMuiTheme } from "@material-ui/core/styles";//Needed to remove finddomnode is deprecated error

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#1CC6D1",
      contrastText: "#fff",
    },
    secondary: {
      main: "#000469",
    },
  },
  overrides: {
    MuiTypography: {
      h6: {
        fontSize: 15,
        fontWeight: 600,
      },
    },

    MuiTableCell: {
      root: {
        padding:"8px 8px"
       },
      paddingDefault: {
        padding: '0px',
      },
     },


  },
  typography: {
    button: {
      textTransform: "none",
    },
  },
});

const App = () => {
  return (
    <RecoilRoot>
        <AuthProvider>
          <MuiThemeProvider theme={theme}>
            <NavBar />
            <Router>
              <div>
                <Route exact path="/" component={OnBoard} />
                <Route exact path="/trainingproviders" component={PricingToggle} />
                <Route exact path="/schools" component={PricingSchools} />
                <Route exact path="/signin" component={SignIn} />
                <Route exact path="/signup" component={SignUp} />
                <PrivateRoute exact path="/purchase" component={Purchase} redirect="/signup" />
                <Route exact path="/account" component={Subscription} redirect="/" />
                <Route exact path="/dashboard" component={Portal} />
                <Route exact path="/onboard" component={OnBoard} />
              </div>
            </Router>
            <Footer />
          </MuiThemeProvider>
        </AuthProvider>
    </RecoilRoot>
  );
};

export default App;
