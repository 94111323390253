import React from "react";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import StarIcon from "@material-ui/icons/StarBorder";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Switch from "@material-ui/core/Switch";
import Chip from "@material-ui/core/Chip";
import DoneIcon from "@material-ui/icons/Done";
import { tiers,  useStyles } from "./PricingData.js";
import {Link as RouterLink} from "react-router-dom";
import { purchaseState } from '../data/PurchaseState';
import { useRecoilState } from "recoil";
import _ from "lodash"
import useLocalStorage from '../hooks/useLocalStorage';
import PricingHero from "./PricingHero.js";

export default function PricingToggle() {
  const classes = useStyles();
  // eslint-disable-next-line
  const [persistedState,setPersistedState] = useLocalStorage('PurchaseState');
  const [state,setState] = useRecoilState(purchaseState);
  
  const getPrice = (tierIndex) => {
    let tier = tiers[tierIndex];
    if (!tier || !tier.price) return 0; //ie Enterprise
    let priceKey = state.products.reduce((total, item) => {
      return total + (item.checked ? item.key : "");
    }, "");
    return tier.price[priceKey][state.billed];
  };

  const getLicenses = () => {
    let licenses = state.products.reduce((total, item) => {
      return total + (item.checked ? (total === "" ? "" : ",") + item.name : "");
    }, "");
    return (
      <>
        <Typography component="li" variant="subtitle1" align="center" key={0} color="textPrimary">
          Licenses for:
        </Typography>
        <Typography component="li" variant="subtitle1" align="center" key={1} color="textSecondary">
          {licenses}
        </Typography>
      </>
    );
  };

  const getPriceFormatted = (tierIndex) => {
    let price = getPrice(tierIndex);
    return price ? (
      <div className={classes.cardPricing}>
        <Typography component="h2" variant="h3" color="textPrimary">
          ${price}
        </Typography>
        <Typography variant="h6" color="textSecondary">
          {state.billed === "Annually" ? "/yr" : "/mo"}
        </Typography>
      </div>
    ) : (
      <div className={classes.cardPricing}>
        <Typography component="h2" variant="h4" color="textPrimary">
          Contact us
        </Typography>
      </div>
    );
  };

  const getCard = (tier, index) => (
      <Grid item key={index} xs={12} sm={6} md={3}>
        <Card>
          <CardHeader
            title={tier.title}
            subheader={tier.subheader}
            titleTypographyProps={{ align: "center" }}
            subheaderTypographyProps={{ align: "center" }}
            action={tier.title === "Pro" ? <StarIcon /> : null}
            className={classes.cardHeader}
          />

          <CardContent>
            {getPriceFormatted(index)}
            <ul>
              {tier.description.map((line, index) => (
                <Typography
                  component="li"
                  variant="subtitle1"
                  align="center"
                  key={line}
                  color={index === 0 ? "textPrimary" : "textSecondary"}
                >
                  {line}
                </Typography>
              ))}
              {getLicenses()}
            </ul>
          </CardContent>

          <CardActions>
            <Button onClick={()=>{setPersistedState({...state,priceIndex:index,total: getPrice(index),price:getPrice(index)})}} to={{pathname: "/purchase",state: {subscription:'Monthly'}}} component={RouterLink} fullWidth variant={tier.buttonVariant} color="primary">
              {tier.buttonText}
            </Button>
          </CardActions>
        </Card>
      </Grid>
  );

  const handleChipClick = (index) => {
    let newState = _.cloneDeep(state); //Need a deep copy
    newState.products[index].checked = !newState.products[index].checked;
    if (!newState.products.find((p) => p.checked)) newState.products[0].checked = true;
    setState({ ...newState });
    setPersistedState({...newState});
  };

  const handleBilledChange = (event) => {
    let newState = { ...state, billed: event.target.checked ? "Monthly" : "Annually" };
    setState(newState);
    setPersistedState(newState);
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <PricingHero/>


      <Container maxWidth="sm" className={classes.chipsContainer}>
        {state.products.map((data, index) => {
          let icon;
          let color;
          if (data.checked) {
            icon = <DoneIcon />;
            color = "primary";
          }
          return (
            <Chip key={index} color={color} label={data.name} icon={icon} onClick={() => handleChipClick(index)} className={classes.chip} />
          );
        })}
      </Container>

      {/* <Container maxWidth="md" component="main" className={classes.toggleContainer}>
        <div className={classes.toggleSwitch}>
          <Typography>Billed Annually</Typography>
          <Switch
            name="billed"
            checked={state.checkedBilling}
            onChange={handleBilledChange}
            inputProps={{ "aria-label": "secondary checkbox" }}
          />
          <Typography>Billed Monthly</Typography>
        </div>
      </Container> */}

      <Container maxWidth="lg" component="main" className={classes.cardsContainer}>
        <Grid container spacing={1} alignItems="flex-end">
          {tiers.map((tier, index) => getCard(tier, index))}
        </Grid>
      </Container>
    </React.Fragment>
  );
}
