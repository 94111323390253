import React, { useState, useContext, useEffect } from "react";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import app from "../services/firebase.js";
import { AuthContext } from "../services/Auth";

//import { tiers, footers, useStyles, Copyright } from "../components/PricingData.js";

const priceFormatted = (price, currency) => {
  return `$${price / 100}`;
};

const dateFormatted = (epoch) => {
  var d = new Date(0);
  d.setUTCSeconds(epoch);
  return d.toDateString();
};

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    margin: theme.spacing(2),
  },
}));

const SubTile = (props) => {
  const classes = useStyles();
  let { title } = props;
  return (
    <Paper className={classes.paper}>
      <Typography component="h2" variant="h5" color="primary" gutterBottom>
        {title}
      </Typography>
      {props.children}

      {/* <div>
        <Link color="primary" href="#">
          Info
        </Link>
      </div> */}
    </Paper>
  );
};

const Subscription = () => {
  const classes = useStyles();
  const { currentUser } = useContext(AuthContext);
  const [subscriptions, setSubscriptions] = useState([]);
  useEffect(() => {
    console.log("SUBSCRIPTION:",currentUser);
    if (!currentUser) return;
    var getSubscriptionDetails = app.functions().httpsCallable("getSubscriptionDetails");
    getSubscriptionDetails({ customerId: currentUser.stripe_id }).then((result) => {
      console.log(result);
      setSubscriptions(result.data.subscriptions);
    });
  }, [currentUser]);

  return (
    <>
      <Container maxWidth="sm" component="main" className={classes.heroContent}>
        <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
          {subscriptions && subscriptions.length === 0 ? 'No Subscriptions' :  'Welcome to Your Subscription'} 
        </Typography>
        <Typography variant="h5" align="center" color="textSecondary" component="p"></Typography>

        <Grid>
          {subscriptions && subscriptions.map((subscription, key) => (
            <div key={key}>
              <SubTile title={subscription.productName}>
                <Typography color="textSecondary">subscribed on {dateFormatted(subscription.created)}</Typography>
                <Typography variant="h5">
                  {priceFormatted(subscription.amount, subscription.currency)} / {subscription.interval}
                </Typography>
              </SubTile>
              <SubTile title="Installation">
                <Link href="http://bit.ly/QuestInstall" color="textPrimary">Quest Installation Guide</Link>
              </SubTile>
            </div >
          ))}
        </Grid>
      </Container>
    </>
  );
};

export default Subscription;
