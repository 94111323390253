import React from "react"
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  heroContent: {
    padding: theme.spacing(8, 0, 4),
  },  
}));

const PricingHero = () => {
  const classes = useStyles();
  return (
    <>
      <Container maxWidth="sm" component="main" className={classes.heroContent}>
        <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
          Products and pricing
        </Typography>
        <Typography variant="h5" align="center" color="textSecondary" component="p">
          Choose your VR training software product and subscription plan that aligns with your needs
        </Typography>
      </Container>
    </>
  )
}

export default PricingHero;