import firebase from 'firebase/app';
import 'firebase/functions';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/database';


const firebaseConfig = {
  apiKey: "AIzaSyC7TmjSFyiWtv2OBtiadIN8A8QAxf4wFPg",
  authDomain: "code-blue-online.firebaseapp.com",
  databaseURL: "https://code-blue-online.firebaseio.com",
  projectId: "code-blue-online",
  storageBucket: "code-blue-online.appspot.com",
  messagingSenderId: "320544914255",
  appId: "1:320544914255:web:9cc530103532c3004b233a"
};


const app = firebase.initializeApp(firebaseConfig);

export default app;