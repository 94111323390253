import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";


export const tiersSchools = [
  {
    title: "Essential",
    price: {
      BLS: { Annually: 2400, Monthly: 460 },
      ACLS: { Annually: 2400, Monthly: 460 },
      OBH: { Annually: 800, Monthly: 460 },
      BLSACLS: { Annually: 3800, Monthly: 460 },
      BLSACLSOBH: { Annually: 4200, Monthly: 460 },
      BLSOBH: { Annually: 2800, Monthly: 460 },
      ACLSOBH: { Annually: 2800, Monthly: 460 },
    },
    description: ["Up to 100 students",  "Email support", "Updates"],
    buttonText: "Get started",
    buttonVariant: "outlined",
  },
  {
    title: "Standard",
    subheader: "Most popular",
    price: {
      BLS: { Annually: 3800, Monthly: 460 },
      ACLS: { Annually: 3800, Monthly: 460 },
      OBH: { Annually: 1200, Monthly: 460 },
      BLSACLS: { Annually: 6200, Monthly: 460 },
      BLSACLSOBH: { Annually: 6600, Monthly: 460 },
      BLSOBH: { Annually: 4200, Monthly: 460 },
      ACLSOBH: { Annually: 4200, Monthly: 460 },
    },
    description: ["Up to 200 students",  "Email support", "Updates", "Analytics"],
    buttonText: "Get started",
    buttonVariant: "contained",
  },
  {
    title: "Advanced",
    price: {
      BLS: { Annually: 4800, Monthly: 460 },
      ACLS: { Annually: 4800, Monthly: 920 },
      OBH: { Annually: 1800, Monthly: 200 },
      BLSACLS: { Annually: 8200, Monthly: 1020 },
      BLSACLSOBH: { Annually: 8800, Monthly: 1120 },
      BLSOBH: { Annually: 5200, Monthly: 560 },
      ACLSOBH: { Annually: 5200, Monthly: 860 },
    },
    description: ["Up to 500 students",  "Priority Email support", "Updates", "Analytics"],
    buttonText: "Get started",
    buttonVariant: "outlined",
  },
  {
    title: "Premium",
    description: ["Unlimited or district usage",  "Priority Email support", "Updates", "Analytics", "Customisation"],
    buttonText: "Contact Us",
    buttonVariant: "outlined",
  },
];




export const tiers = [
  {
    title: "Essential",
    price: {
      BLS: { Annually: 900, Monthly: 89 },
      ACLS: { Annually: 1800, Monthly: 178 },
      OBH: { Annually: 450, Monthly: 50 },
      BLSACLS: { Annually: 2400, Monthly: 240 },
      BLSACLSOBH: { Annually: 2600, Monthly: 260 },
      BLSOBH: { Annually: 1300, Monthly: 130 },
      ACLSOBH: { Annually: 2000, Monthly: 200 },
    },
    description: ["Up to 50 students",  "Email support", "Updates"],
    buttonText: "Get started",
    buttonVariant: "outlined",
  },
  {
    title: "Advanced",
    subheader: "Most popular",
    price: {
      BLS: { Annually: 2400, Monthly: 250 },
      ACLS: { Annually: 4800, Monthly: 500 },
      OBH: { Annually: 1200, Monthly: 130 },
      BLSACLS: { Annually: 6400, Monthly: 640 },
      BLSACLSOBH: { Annually: 6800, Monthly: 680 },
      BLSOBH: { Annually: 3400, Monthly: 340 },
      ACLSOBH: { Annually: 5200, Monthly: 520 },
    },
    description: ["Up to 200 students",  "Email support", "Updates", "Analytics"],
    buttonText: "Get started",
    buttonVariant: "contained",
  },
  {
    title: "Professional",
    price: {
      BLS: { Annually: 4000, Monthly: 460 },
      ACLS: { Annually: 8000, Monthly: 920 },
      OBH: { Annually: 2000, Monthly: 200 },
      BLSACLS: { Annually: 10200, Monthly: 1020 },
      BLSACLSOBH: { Annually: 11200, Monthly: 1120 },
      BLSOBH: { Annually: 5600, Monthly: 560 },
      ACLSOBH: { Annually: 8600, Monthly: 860 },
    },
    description: ["Up to 500 students",  "Priority Email support", "Updates", "Analytics"],
    buttonText: "Get started",
    buttonVariant: "outlined",
  },
  {
    title: "Enterprise",
    description: ["Over 500 students",  "Priority Email support", "Updates", "Analytics", "Customisation"],
    buttonText: "Contact Us",
    buttonVariant: "outlined",
  },
];



export const useStyles = makeStyles((theme) => ({
  "@global": {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: "none",
    },
  },
  appBar: {
    padding:theme.spacing(1, 0),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  logo: {
    padding:theme.spacing(1,0,0,4),
  },
  toolbar: {
    // flexWrap: "wrap",
    justifyContent: "space-between"
  },
  toolbarTitle: {
    // flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 4),
  },
  cardHeader: {
    backgroundColor: theme.palette.type === "light" ? theme.palette.grey[200] : theme.palette.grey[700],
  },
  cardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
  chipsContainer: {
    padding: theme.spacing(2, 0),
    display: "flex",
    justifyContent: "center",
  },
  chip: {
    fontSize: 20,
    padding: theme.spacing(2.5, 2),
    borderRadius: 100,
    margin: theme.spacing(0.5),
  },
  toggleContainer: {
    padding: theme.spacing(2, 0, 4, 0),
  },
  toggleSwitch: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(1, 0),
    // backgroundColor: "#FF0000",
  },
  cardsContainer: {
    padding: theme.spacing(0, 6),
  },
}));



