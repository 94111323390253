import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";


const footers = [
  {
    title: "Company",
    description: [{title: "Contact us",link : "mailto:hello@dualgoodhealth.com"}],
  },
  {
    title: "Information",
    description: [{title:"Blog",link:"https://medium.com/dualgoodhealth"}, {title:"Press",link:"mailto:hello@dualgoodhealth.com"}, {title:"Technical support",link:"mailto:morgan@dualgoodhealth.com"}],
  },
  {
    title: "Follow us",
    description: [{title:"Facebook",link:"https://www.facebook.com/dualgoodhealth/"}, {title:"Instagram",link:"https://www.instagram.com/dualgoodhealth/"}, {title:"Twitter",link:"https://twitter.com/dualgoodhealth"},{title:"LinkedIn",link:"https://www.linkedin.com/company/11074598"},{title:"Youtube",link:"https://www.youtube.com/channel/UC0g1qFICppnO9st75dA_OLQ?view_as=subscriber"}],
  },
  {
    title: "Legal",
    description: [{title:"Privacy policy",link:"https://www.dropbox.com/s/wlrpiz6kgcgvtnk/Privacy%20policy%20%28DGH%29_v1.doc?dl=0"}, {title:"Terms of use",link:"https://www.dropbox.com/s/8w8vgy5ryn0h8ty/Dual%20Good%20Health%20End%20User%20License%20Agreement%20%28EULA%29.docx?dl=0"}],
  },
];

const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://www.dualgoodhealth.com/">
        Dual Good Health Limited
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export const useStyles = makeStyles((theme) => ({
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
}));

const Footer = ()=>{
  const classes = useStyles();
  return(
    <>

      <Container maxWidth="md" component="footer" className={classes.footer}>
      <Grid container spacing={4} justify="space-evenly">
          {footers.map((footer) => (
            <Grid item xs={6} sm={3} key={footer.title}>
              <Typography variant="h6" color="textPrimary" gutterBottom>
                {footer.title}
              </Typography>
              <ul>
                {footer.description.map((item,index) => (
                  <li key={index}>
                    <Link href={item.link} variant="subtitle1" color="textSecondary">
                      {item.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </Grid>
          ))}
        </Grid>
        <Box mt={5}>
          <Copyright />
        </Box>

      </Container>


    </>
  )

}

export default Footer;