import { atom ,selector} from "recoil";

export const purchaseState = atom({
  key:'purchaseState',
  default: {
    products: [
      { key: "BLS", name: "BLS", checked: true },
      { key: "ACLS", name: "ACLS & PALS", checked: false },
      { key: "OBH", name: "OBH", checked: false },
    ],
    billed: "Annually",
    priceIndex: 0,
    price: 0,
  }
});

export const priceKeyState = selector({
  key: 'priceKeyState', 
  get: ({get}) => {
    const purchase = get(purchaseState);
    let priceKey = purchase.products.reduce((total, item) => {
      return total + (item.checked ? item.key : "");
    }, "");
    return priceKey;
  },
});

// export const priceState = selector({
//   key: 'priceState', 
//   get: ({get}) => {
//     const purchase = get(purchaseState);
//     let priceKey = purchase.products.reduce((total, item) => {
//       return total + (item.checked ? item.key : "");
//     }, "");
//     return priceKey;
//   },
// });




