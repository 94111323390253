import React from "react";

const circleColor = (current, number) => {
  if (current > number) return "#000469";
  else if (current === number) return "#1CC6D1";
  else return "#828282";
};

const lineColor = (current, number) => {
  if (current > number) return "#000469";
  else return "#828282";
};


const ProgressCircle = (props) => {
  let { last, number, title, current } = props;
  return (
    <>
      <svg width="70px" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <style></style>
        <circle cx="50" cy="50" r="47" stroke={circleColor(current, number)} strokeWidth="6" fillOpacity="0" />
        <text
          fontFamily="Nunito Sans"
          fontSize="3em"
          x="50%"
          y="50%"
          textAnchor="middle"
          stroke={circleColor(current, number)}
          fill={circleColor(current, number)}
          strokeWidth="2px"
          dy=".3em"
        >
          {number}
        </text>
        <text fontFamily="Nunito Sans" fontSize="1.5em" x="50%" y="128%" textAnchor="middle" fill={circleColor(current, number)} stroke={circleColor(current, number)} strokeWidth="1px" dy=".3em">
          {title}
        </text>
      </svg>
      {!last && (
        <svg width="50px" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
          <line x1="0" y1="50" x2="100" y2="50" stroke={lineColor(current, number)} strokeWidth="6" />
        </svg>
      )}
    </>
  );
};

export default ProgressCircle;
