import React, { useEffect, useContext, useState } from "react";
import app from "../services/firebase.js";
import firebase from "firebase/app";
import { AuthContext } from "../services/Auth";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  Typography,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Switch,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Grid,
  Box,
} from "@material-ui/core";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import { startOfToday, differenceInDays, differenceInMonths, format } from "date-fns";
//import {DeleteIcon,ExpandLessIcon} from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import { Icon, InlineIcon } from '@iconify/react';
import chevronUpCircleOutline from '@iconify-icons/mdi/chevron-up-circle-outline';

const useStyles = makeStyles((theme) => ({
  card: {
    margin: "20px",
    color: "primary",
    paddingBottom:"20px"
  },

  cardHeader: {
    // backgroundColor: theme.palette.type === "light" ? theme.palette.grey[200] : theme.palette.grey[700],
    padding: "20px 20px 0px 20px",
  },

  gridLineContainer: {
    paddingBottom: "20px",
  },

  gridLineHeader: {
    margin: "0 10px",
    border: "1px solid #fff",
    borderRadius: "10px",
    padding: "10px 10px",
  },

  gridLine: {
    margin: "0px 10px",
    padding: 0,
  },
  inputLine: {
    margin: "2px 0px",
    // maxWidth: "160px",
    // padding: "10px 0 10px 0"
  },
  line: {
    height: "1px",
    width: "100%",
    backgroundColor: "#ddd",
  },
  progressbar: {
    height: "8px",
    width: "100%",
    borderRadius: "4px",
    backgroundColor: "#eee",
  },
  progressbarInner: {
    borderRadius: "4px",
    height: "100%",
    display: "block",
  },
  iconbutton: {
    padding:"0",
    boxShadow:"None",
    fontSize:"34px",
    '&:hover' : {
      boxShadow:"None",
      backgroundColor:"#FFF",
      color:"#138A92"
    }
  },
  


}));

const Learners = () => {
  const classes = useStyles();
  const { currentUser } = useContext(AuthContext);
  const [learners, setLearners] = useState([]);
  const [newLearner, setNewLearner] = useState({ name: "", email: "" ,show:true});
  const [showLearnerPanel,setShowLearnerPanel] = useState(true);


  const shortDate = (longdate) => {
    if(!longdate) return "";
    let d = new Date(longdate);
    return format(d,"yyyy-MM-dd");
  }

  useEffect(() => {
    if (!currentUser) return;
    var db = app.database();
    var learns = db.ref(`users/${currentUser.uid}/learners`);
    learns.on("value", function (snapshot) {
      let ls = snapshot.val();
      setLearners(ls);
    });
  }, [currentUser]);

  const handleChange = (e, key) => {
    const { target } = e;
    var db = app.database();
    var learns = db.ref(`users/${currentUser.uid}/learners/${key}`);
    let aUpdate = { [target.name]: target.value };
    learns.update(aUpdate);
  };

  const handleNewLearner = (e) => {
    const { target } = e;
    let learner = { ...newLearner };
    learner[target.name] = target.value;
    setNewLearner(learner);
  };

  const handleAdd = () => {
    var db = app.database();
    let newKey = learners.length;
    var learns = db.ref(`users/${currentUser.uid}/learners/${newKey}`);
    learns.update(newLearner);
    setNewLearner({ name: "", email: "" ,show:true});
  };

  const handleToggle = (e, key) => {
    const { target } = e;
    var db = app.database();
    var learns = db.ref(`users/${currentUser.uid}/learners/${key}`);
    let aUpdate = { [target.name]: target.checked };
    learns.update(aUpdate);
  };

  const trainingDue = (last_trained) => {
    if (!last_trained) return `0 days`;
    let last = new Date(last_trained);
    let today = startOfToday();
    let months = 12 - differenceInMonths(today, last);
    if (months <= 1) {
      let days = 365 - differenceInDays(today, last);
      return `${days} days`;
    } else {
      return `${months} months`;
    }
  };

  const progress = (last_trained) => {
    if (!last_trained) return 0;
    let last = new Date(last_trained);
    let today = startOfToday();
    let days = differenceInDays(today, last);
    let prog = 100 - (days / 365) * 100;
    return prog;
  };

  const handleReminder = (key) => {
    let { name, email, last_trained } = learners[key];
    if (!email) {
      alert("Please enter an email address!");
      return;
    }
    let training_due = trainingDue(last_trained);
    let db = app.firestore();
    let emailSubject = `BLS Training due in ${training_due}`;
    let emailBody = `<p>Hi ${name},</p><p>Your BLS Training is due in ${training_due}</p>`;
    db.collection(`mail`)
      .add({
        user: currentUser.uid,
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        to: email,
        message: {
          subject: emailSubject,
          html: emailBody,
        },
      })
      .then(function (docRef) {
        console.log("Document written with ID: ", docRef.id);
        alert("Email Sent!");
      })
      .catch(function (error) {
        console.error("Error adding document: ", error);
      });
  };

  function hsl_col_perc(percent) {
    let start = 0;
    let end = 120;
    var a = percent / 100,
      b = (end - start) * a,
      c = b + start;

    // Return a CSS HSL string
    return "hsl(" + c + ", 100%, 46%)";
  }

  const progressColor = (last_trained) => {
    if (!last_trained) return hsl_col_perc(0);
    return hsl_col_perc(progress(last_trained));
  };

  const progressBar = (last_trained) => {
    if (!last_trained) return { width: "0%", backgroundColor: hsl_col_perc(0) };
    // let last = new Date(last_trained);
    // let today = startOfToday();
    // let days = differenceInDays(today, last);
    let prog = progress(last_trained);
    // console.log(progress, last_trained);
    let hsl_col = hsl_col_perc(prog);
    return { width: `${prog}%`, backgroundColor: hsl_col, color: hsl_col };
  };

  const showEmailDialog = (key) => {
    setCurrentLearnerKey(key);
    setOpen(true);
  };

  const handleDelete = (key) => {
    let result = window.confirm("Confirm DELETION!");
    if (result && key) {
      var db = app.database();
      var learns = db.ref(`users/${currentUser.uid}/learners/${key}`);
      learns.remove();
    }
  };

  const [open, setOpen] = useState(false);
  const [currentLearnerKey, setCurrentLearnerKey] = useState(0);
  return (
    <>
      <Dialog open={open}>
        <DialogTitle id="alert-dialog-title">{`Send Email?`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You are about to send an email to <strong>{learners[currentLearnerKey] && learners[currentLearnerKey].name}</strong> at{" "}
            <strong>{learners[currentLearnerKey] && learners[currentLearnerKey].email}</strong>.
            <br />
            <br />
            Do you wish to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
              handleReminder(currentLearnerKey);
            }}
            color="primary"
            variant="contained"
          >
            OK
          </Button>
          <Button
            onClick={() => {
              setOpen(false);
            }}
            color="primary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Card className={classes.card} >
        <Grid container alignItems="center" justify="space-between" className={classes.cardHeader}>
          <Typography color="primary" variant="h5">
            Learners
          </Typography>
          <IconButton className={classes.iconbutton} color="primary" onClick={() =>{setShowLearnerPanel(!showLearnerPanel)}}>
            <Icon icon={chevronUpCircleOutline} rotate={showLearnerPanel ? "0" : "180deg"} />
          </IconButton>
        </Grid>
        <Box display={showLearnerPanel ? "block" : "none"}>
        <TableContainer >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography color="secondary" variant="h6">
                    Learners
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="secondary" variant="h6">
                    Email
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="secondary" variant="h6">
                    Last trained
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="secondary" variant="h6">
                    Training due
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="secondary" variant="h6">
                    Auto Reminders
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="secondary" variant="h6">
                    Instant Reminder
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="secondary" variant="h6">
                    Delete
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {learners.map((l, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <input
                      type="text"
                      placeholder="Enter student name..."
                      value={l.name}
                      onChange={(e) => handleChange(e, index)}
                      name="name"
                      className={classes.inputLine}
                    />
                  </TableCell>
                  <TableCell>
                    <input
                      type="text"
                      placeholder="Enter student email..."
                      value={l.email}
                      onChange={(e) => handleChange(e, index)}
                      name="email"
                      className={classes.inputLine}
                    />
                  </TableCell>
                  <TableCell>
                    <input
                      type="date"
                      value={shortDate(l.last_trained)}
                      onChange={(e) => handleChange(e, index)}
                      name="last_trained"
                      className={classes.inputLine}
                      style={{ padding: "8px 2px", margin: "4px 4px" }}
                    />
                  </TableCell>
                  <TableCell>
                    <div style={{ color: progressColor(l.last_trained), display: "flex", alignItems: "center", minWidth: "150px" }}>
                      <div className={classes.progressbar}>
                        <span className={classes.progressbarInner} style={progressBar(l.last_trained)}></span>
                      </div>
                      <p style={{ fontSize: "11px", width: "100px", paddingLeft: "8px" }}>{trainingDue(l.last_trained)}</p>
                    </div>
                  </TableCell>
                  <TableCell>
                    OFF
                    <Switch color="primary" name="reminder" checked={l.reminder || false} onChange={(e) => handleToggle(e, index)} />
                    ON
                  </TableCell>
                  <TableCell>
                    <Button
                      disabled={!(l.email || false)}
                      onClick={() => showEmailDialog(index)}
                      variant="contained"
                      color="primary"
                      startIcon={<MailOutlineIcon />}
                    >
                      Send a reminder
                    </Button>
                  </TableCell>
                  <TableCell>
                    <IconButton aria-label="delete" onClick={() => handleDelete(index)}>
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              <TableRow key={-1}>
                <TableCell style={{borderBottom:"0"}}>
                  <input
                    type="text"
                    placeholder="Add student name..."
                    value={newLearner.name}
                    onChange={(e) => handleNewLearner(e)}
                    name="name"
                    className={classes.inputLine}
                  />
                </TableCell>
                <TableCell style={{borderBottom:"0"}}>
                  <input
                    type="text"
                    placeholder="Add student email..."
                    value={newLearner.email}
                    onChange={(e) => handleNewLearner(e)}
                    name="email"
                    className={classes.inputLine}
                  />
                </TableCell>
                <TableCell style={{borderBottom:"0"}}>
                  <Button onClick={handleAdd} color="primary" variant="contained" disabled={!newLearner.name}>
                    ADD LEARNER
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        </Box>
      </Card>
    </>
  );

  // return (
  //   <>
  //     <Card className={classes.card}>
  //       <Grid container spacing={1} alignItems="left" justify="left" className={classes.grow}>
  //         <Grid item xs={12}>
  //           <Grid container alignItems="left" justify="space-between" className={classes.cardHeader}>
  //             <Typography color="primary" variant="h5">
  //               Learners
  //             </Typography>
  //           </Grid>
  //         </Grid>
  //         <Grid container className={classes.gridLineContainer}>
  //           <Grid container className={classes.gridLineHeader}>
  //             <Grid item xs={2}>
  //               <Typography color="secondary" variant="h6">
  //                 Name
  //               </Typography>
  //             </Grid>
  //             <Grid item xs={2}>
  //               <Typography color="secondary" variant="h6">
  //                 Email
  //               </Typography>
  //             </Grid>
  //             <Grid item xs={2}>
  //               <Typography color="secondary" variant="h6" style={{padding: "0 10px"}}>
  //                 Last trained
  //               </Typography>
  //             </Grid>
  //             <Grid item xs={2}>
  //               <Typography color="secondary" variant="h6">
  //                 Training due
  //               </Typography>
  //             </Grid>
  //             <Grid item xs={1}>
  //               <Typography color="secondary" variant="h6">
  //                 Reminders
  //               </Typography>
  //             </Grid>
  //             <Grid item xs={2}>
  //               <Typography color="secondary" variant="h6">
  //                 Instant reminder
  //               </Typography>
  //             </Grid>
  //             <div className={classes.line}></div>
  //           </Grid>

  //           {learners.map((l, index) => (
  //             <Grid key={index} container className={classes.gridLine} alignItems="left">
  //               <Grid item xs={2} className={classes.inputLine}>
  //                 <input
  //                   type="text"
  //                   placeholder="Enter student name..."
  //                   value={l.name}
  //                   onChange={(e) => handleChange(e, index)}
  //                   name="name"
  //                   className={classes.inputLine}
  //                 />
  //               </Grid>
  //               <Grid item xs={2} className={classes.inputLine}>
  //                 <input
  //                   type="text"
  //                   placeholder="Enter student email..."
  //                   value={l.email}
  //                   onChange={(e) => handleChange(e, index)}
  //                   name="email"
  //                   className={classes.inputLine}
  //                 />
  //               </Grid>
  //               <Grid item xs={2} >
  //                 <input
  //                   type="date"
  //                   value={l.last_trained}
  //                   onChange={(e) => handleChange(e, index)}
  //                   name="last_trained"
  //                   className={classes.inputLine}
  //                   style={{ padding: "8px 2px",margin: "4px 4px" }}
  //                 />
  //               </Grid>

  //               <Grid item xs={2} >
  //                 <input
  //                   type="date"
  //                   value={l.last_trained}
  //                   onChange={(e) => handleChange(e, index)}
  //                   name="last_trained"
  //                   className={classes.inputLine}
  //                   style={{ padding: "8px 2px",margin: "4px 4px" }}
  //                 />
  //               </Grid>

  //             </Grid>
  //           ))}

  //           <Grid key={-1} container className={classes.gridLine} alignItems="center">
  //             <Grid item xs={3} className={classes.inputLine}>
  //               <input type="text" placeholder="Add student here..." onChange={(e) => {}} name="name" className={classes.inputLine} />
  //             </Grid>
  //             <Grid item xs={3} className={classes.inputLine}>
  //               <input type="text" placeholder="Add email here..." onChange={(e) => {}} name="email" className={classes.inputLine} />
  //             </Grid>
  //           </Grid>
  //         </Grid>
  //       </Grid>
  //     </Card>
  //   </>
  // );
};

export default Learners;
