import React, { useEffect, useContext, useState } from "react";
import app from "../services/firebase.js";
import { AuthContext } from "../services/Auth";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import {Typography, Button } from "@material-ui/core";
import { CSVLink } from "react-csv";
import {format, formatISO} from "date-fns";
import Learners from "../components/Learners.js";

const useStyles = makeStyles((theme) => ({
  btn: {
    textDecoration: "None",
  },

  card: {
    margin: "20px",
  },

  cardHeader: {
    // backgroundColor: theme.palette.type === "light" ? theme.palette.grey[200] : theme.palette.grey[700],
    padding: "20px 20px 0px 20px",
  },

  inputField: {
    height: 28,
    padding: 10,
    margin: 0,
    width: 180,
  },

  gridLineContainer: {
    paddingBottom: "20px",
  },

  gridLineHeader: {
    margin: "0 10px",
    border: "1px solid #fff",
    borderRadius: "10px",
    padding: "10px 10px",
  },

  gridLine: {
    margin: "5px 10px",
    border: "1px solid #ddd",
    borderRadius: "10px",
    padding: "8px",
  },

  tableContainer: {
    width: 600,
    margin: "20px",
  },
  table: {
    width1: 450,
    padding: "0px",
  },
  tableRow: {
    border: "2px solid #73AD21",
    borderRadius: "20px",
  },
  line: {
    height:"1px",
    width:"100%",
    backgroundColor:"#ddd"
  }


}));

const Portal = () => {
  const [selectedDate, setSelectedDate] = useState(format(new Date(),'yyyy-MM-dd'));
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const classes = useStyles();
  const { currentUser } = useContext(AuthContext);
  const [analytics, setAnalytics] = useState({});

  const handleChange = (e, key) => {
    const { target } = e;
    var db = app.database();
    var analytic = db.ref(`users/${currentUser.uid}/analytics/${key}`);
    let aUpdate = { [target.name]: target.value };
    analytic.update(aUpdate);
  };

  useEffect(() => {
    if (!currentUser) return;
    var db = app.database();
    var perfs = db.ref(`users/${currentUser.uid}/analytics`);
    perfs.on("value", function (snapshot) {
      let p = snapshot.val();
      let newPerformances = [];
      let newAnalytics = {};
      p &&
        Object.entries(p)
          .filter(([key, value]) => value.analytic_type === "experience_end" && formatISO(Date.parse(value.datecreated),{representation: 'date'}) === selectedDate)
          .forEach(([key, value]) => {
            newPerformances.push({ id: key, score: value.score, time: new Date(Date.parse(value.datecreated)).toLocaleTimeString() });
            newAnalytics[key] = {
              location:value.location,
              student_name: value.student_name,
              score: value.score,
              time: new Date(Date.parse(value.datecreated)).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}),
            };
          });
      //setPerformances(newPerformances);
      setAnalytics(newAnalytics);
    });
  }, [currentUser,selectedDate]);

  return (
    <>
      <Container component="main">

        <Learners/>


        <Card className={classes.card}>
          <Grid container spacing={1} alignItems="center" justify="center" className={classes.grow}>
            <Grid item xs={12}>
              <Grid container alignItems="center" justify="space-between" className={classes.cardHeader}>
                <Typography color="primary" variant="h5">Learner Scores</Typography>
                <input type="date" id="student-score-date" name="student-score-date" value={selectedDate} onChange={(e) => handleDateChange(e.target.value)}/>
                <CSVLink data={Object.values(analytics)} filename={`student-scores-${selectedDate}.csv`} className={classes.btn}>
                  <Button variant="contained" color="primary">
                    Export to CSV
                  </Button>
                </CSVLink>
              </Grid>
            </Grid>
            <Grid container className={classes.gridLineContainer}>
              <Grid container className={classes.gridLineHeader}>
              <Grid item xs={2}>
                  <Typography color="secondary" variant="h6" style={{ textAlign: "center" }}>Location</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography color="secondary" variant="h6">Name</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography color="secondary" variant="h6" style={{ textAlign: "center" }}>
                    Score
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography color="secondary" variant="h6" style={{ textAlign: "center" }}>
                    Time
                  </Typography>
                </Grid>
                <div className={classes.line}></div>
              </Grid>

              {Object.entries(analytics).map(([key, value]) => (
                <Grid key={key} container className={classes.gridLine} alignItems="center">
                  <Grid item xs={2} style={{ textAlign: "center" }}>
                    {value.location}
                  </Grid>
                  <Grid item xs={5}>
                    <input
                      type="text"
                      placeholder="Enter student name..."
                      value={value.student_name}
                      onChange={(e) => handleChange(e, key)}
                      name="student_name"
                      className={classes.inputField}
                    />
                  </Grid>
                  <Grid item xs={2} style={{ textAlign: "center" }}>
                    {value.score}
                  </Grid>
                  <Grid item xs={3} style={{ textAlign: "center" }}>
                    {value.time}
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Card>
      </Container>
    </>
  );
};

export default Portal;
