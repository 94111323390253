import React, { useCallback, useContext, useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import app from "../services/firebase";
import { AuthContext } from "../services/Auth";
import { Redirect } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import { FormControlLabel, Checkbox } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  checkbox: {
    margin: theme.spacing(1,0),
  }
}));

export default function SignUp({ history, from }) {
  //const { location } = props.location.state;
  //console.log(from);
  const [loading, setLoading] = useState(false);
  const [state,setState] = useState({
    checkedA: true,
    checkedB:true,
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleSignUp = useCallback(
    async (event) => {
      event.preventDefault();
      const { email, password } = event.target.elements;
      console.log(`email:${email.value} password:${password.value}`);
      try {
        setLoading(true);
        const userRef = await app.auth().createUserWithEmailAndPassword(email.value, password.value);
        if(userRef && userRef.user){
          var db = app.database();
          var userInfo = db.ref(`users/${userRef.user.uid}/information`);
          userInfo.update({ email_marketing: state.checkedB });
        }
        history.push("/purchase");
      } catch (error) {
        alert(error);
      }
    },
    [history]
  );

  const classes = useStyles();
  const { currentUser } = useContext(AuthContext);
  if (currentUser) return <Redirect to="/purchase" />;

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        {loading ? (
          <BeatLoader />
        ) : (
          <form className={classes.form} noValidate onSubmit={handleSignUp}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />

            <FormControlLabel className={classes.checkbox} control={<Checkbox checked={state.checkedA} onChange={handleChange} name="checkedA" color="primary" />} label={<Typography variant="subtitle2">I agree to DGH collecting and storing the data from this form</Typography>} />
            <FormControlLabel className={classes.checkbox} control={<Checkbox checked={state.checkedB} onChange={handleChange} name="checkedB" color="primary" />} label={<Typography variant="subtitle2">I am happy to receive occassional updates, marketing and promotional emails</Typography>} />



            <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit} disabled={!state.checkedA}>
              Sign Up
            </Button>
            <Grid container justify="flex-end">
              <Grid item>
                <Link href="/signin" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </form>
        )}
      </div>
    </Container>
  );
}
