import { AppBar, Container, ListItem, ListItemText, Typography } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ProgressCircle from "../components/ProgressCircle";
import OnBoardSection from "../components/OnBoardSection";
import useScrollPosition from "@react-hook/window-scroll";
import { red } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    paddingTop: theme.spacing(8),
    paddingBottom:theme.spacing(1),
    backgroundColor: "white",
  },
  title: {
    textAlign: "center",
    color: "#000469",
    fontWeight: 700,
    backgroundColor: "white",
  },
  subtitle: {
    textAlign: "center",
    fontSize: "1.35em",
    fontWeight: 600,
    color: "#828282",
    padding: theme.spacing(1, 0),
    backgroundColor: "white",
  },
  progressBar: {
    position: "sticky",
    display: "flex",
    flexDirection: "row",

    justifyContent: "center",
    padding: theme.spacing(0, 0),
    height: "160px",
    background: "white",
    top: "90px",
    zIndex: 0,
    backgroundColor: "white",
  },
  bulletList: {
    listStyleType: "square",
  },
}));

const OnBoard = () => {
  const classes = useStyles();
  const maxProgress = 6;
  const scrollY = useScrollPosition(60);
  let currentProgress = 0;
  if (scrollY > 1800) currentProgress = 6;
  else if (scrollY > 1500) currentProgress = 5;
  else if (scrollY > 1200) currentProgress = 4;
  else if (scrollY > 900) currentProgress = 3;
  else if (scrollY > 530) currentProgress = 2;
  else if (scrollY > 220) currentProgress = 1;
  let progress = [];
  let titles = ["VR space", "Wi-fi","Hands", "Interaction", "Start app", "Welcome"];
  for (let index = 0; index < maxProgress; index++) {
    progress.push(
      <ProgressCircle key={index} number={index + 1} current={currentProgress} title={titles[index]} last={index === maxProgress - 1} />
    );
  }
  return (
    <>
      <div className={classes.titleContainer}>
        <Typography variant="h3" className={classes.title}>
          How to get started?
        </Typography>
        <Typography className={classes.subtitle}>Get started with your Quest Headset in 6 easy steps</Typography>
      </div>
      <div position="sticky" className={classes.progressBar} style={{ backgroundColor: currentProgress > 0 && "#F2F2F2" }}>
        {progress}
      </div>
      <OnBoardSection
        number={1}
        title="Draw your Guardian"
        videoId="zh5ldprM5Mg"
        description="You will need this step to define your “play area” - the space you can use to conduct your training"
      />
      <OnBoardSection
        number={2}
        title="Join a Wi-fi network"
        image="quest-wifi.gif"
        description="You will need internet access to use our software. Simply connect your headset to your best local Wifi network."
      >
        <ol>
          <li>
            Press <img src="oculus.png" width="20" /> on your right Touch controller to pull up your universal menu.
          </li>
          <li>Select Wi-Fi.</li>
          <li>Select the Wi-Fi network you'd like to connect to.</li>
          <li>If prompted, enter the password for the Wi-Fi network you'd like to connect to.</li>
          <li>Select Connect to connect to the Wi-Fi network.</li>
        </ol>
      </OnBoardSection>

      <OnBoardSection
        number={3}
        title="Enable Hand Tracking"
        image="hand-tracking-setup.gif"
        description="You may need to enable hand tracking, if so follow these instructions."
      >
        <ol>
          <li>
            Press <img src="oculus.png" width="20" /> on your right Touch controller to pull up your universal menu.
          </li>
          <li>Select Settings.</li>
          <li>Choose Device.</li>
          <li>Choose Hand Controllers.</li>
          <li>Toggle Hand Tracking On.</li>
        </ol>
      </OnBoardSection>


      <OnBoardSection
        number={4}
        title="Hand gestures & interaction"
        image="hand-tracking.gif"
        description="This step is fun - simply test out and learn how you can interact with VR menus and items with your hands."
      >
        <ul className={classes.bulletList}>
          <li>Pinch to select</li>
          <li>Pinch and drag to scroll</li>
        </ul>
      </OnBoardSection>
      <OnBoardSection
        number={5}
        title="Start the DGH app"
        image="start-app.gif"
        description="In this video, you will learn how to start the BLS app."
      >
        <ol>
          <li>
            Press <img src="oculus.png" width="20" /> on your right Touch controller to pull up your universal menu.
          </li>
          <li>
            Press the apps icon <img src="apps_icon.png" width="20" /> on the universal menu.
          </li>
          <li>Press 'Basic Life Support HT'</li>
        </ol>
      </OnBoardSection>
      <OnBoardSection
        number={6}
        title="Welcome to DGH BLS app"
        videoId="Z9V-Yl11rTU"
        description="Quick quide to our menu and interactions. Welcome to the DGH BLS app!"
      />
      <OnBoardSection
        number={7}
        title="WHO Prototype Instructions"
        image="cpr.jpg"
        description="For the purposes of the Evaluation stage, please train on the CPR module, as seen on the image to the right."
      />

    </>
  );
};

export default OnBoard;
