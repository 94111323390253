import React, { useContext } from "react";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
//import { useStyles } from "../components/PricingData.js";
import Toolbar from "@material-ui/core/Toolbar";
import Link from "@material-ui/core/Link";
import { AuthContext } from "../services/Auth.js";
import app from "../services/firebase.js";
import CssBaseline from "@material-ui/core/CssBaseline";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { Hidden } from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
  "@global": {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: "none",
    },
  },
  appBar: {
    padding:theme.spacing(1, 0),
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor:"white"
  },
  logo: {
    padding:theme.spacing(1,0,0,4),
  },
  toolbar: {
    // flexWrap: "wrap",
    justifyContent: "space-between"
  },
  toolbarTitle: {
    // flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 4),
  },
}));



const NavBar = () => {
  const classes = useStyles();
  const { currentUser } = useContext(AuthContext);
  return (
    <>
      <CssBaseline />
      <AppBar position="sticky" color="default" elevation={4} className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
            <Link href="/" style={{ textDecoration: "none" }}>
            <Hidden only="xs">
              <img className={classes.logo} src={"./simhub-logo.svg"} alt="logo" />
              </Hidden>
            </Link>
          <Box display="flex" flexDirection="row" alignItems="center">
          <Link variant="button" color="textPrimary" href="/dashboard" className={classes.link} style={{ textTransform: "none" }}>
              Dashboard
            </Link>
            <Link variant="button" color="textPrimary" href="/account" className={classes.link} style={{ textTransform: "none" }}>
              Account
            </Link>

            {currentUser ? (
              <>
                <p fontSize={14}>Hello {currentUser.email}</p>
                <Button onClick={() => app.auth().signOut()} href="#" color="primary" variant="outlined" className={classes.link} >
                  Sign Out
                </Button>
              </>
            ) : (
              <Button href="/signin" color="primary" variant="outlined" className={classes.link}>
                Sign in
              </Button>
            )}
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default NavBar;
